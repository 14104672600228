.top-introduction {
	float: left;
	width: 100%;
	background: $springwood;
	margin-top: 35px;
	@include breakpoint($bp-charlie) {
		margin-top: 55px;
	}
	.col-md-6, .col-md-7, .col-md-8, .col-md-9 {
		float: none;
		display: table;
		margin: 0 auto;
	}
}


.issues-page {
	float: left;
	width: 100%;
	font-size: 0px;
	.container {
		max-width: 1230px;
		width: 100%;
	}
	&.inpage {
		h3, p {
			text-align: center;
		}
		h3{
			padding: 80px  0 0;
		}
		p {
			margin-bottom: 0px;
		}
	}
	&.person {
		.issues-grid {
			padding: 0px;
		}
		.single-issue {
			background: white;
			height: 380px;
			.grid-image-container {
				height: 45%;
				background: $secondary;
				.grid-image {
					opacity: 1;
				}
			}
			&:hover{
				background: $mallard;
				.grid-image-container {
					background: $mallard;
				}
			}
			.wrapper {
				top: auto;
				transform: none;
				bottom: 0;
				padding: 35px 35px 50px 35px;
				z-index: 99;
				h6, p, small {
					padding: 0px;
					text-align: left;
					word-wrap: break-word;
				}
				h6 {
					margin: 0 0 5px;
				}
				small {
					color: $details;
				}
				p {
					font-size: 15px;
					max-height: 0;
					opacity: 0;
					margin: 0;
					overflow: hidden;
					@include hover-fade();
				}
				.text-container__btn {
					text-align: center;
					font-family: $headline-font;
					font-size: 12px;
					font-weight: 600;
					text-transform: uppercase;
					letter-spacing: 0.08em;
					background: $primary;
					color: #fff;
					display: block;
					max-height: 0;
					opacity: 0;
					overflow: hidden;
					padding: 17px 15px;
					border-radius: $buttonBorderRadius;
					position: relative;
					z-index: 99;
					line-height: 1;
					@include hover-fade();
					@include transform(scale(0.85));
					&:hover {
						background-color: #fff;
						color: $secondary;
						
					}
				}
				.text-container {
					max-width: 100%;
					display: block;
					float: left;
					@include hover-fade();
				}
			}
			&:hover {
				.grid-image-container {
					.grid-image {
						opacity: 0;
					}
				}
				.wrapper {
					p {
						max-height: 380px;
						opacity: 1;
						margin-top: 30px;
					}
					.text-container__btn {
						max-height: 380px;
						opacity: 1;
						margin-top: 18px;
					}
					.text-container {
						margin-bottom: 45px;
						&.no-social {
							margin-bottom: 0px;
						}
					}
				}
			}
			@include breakpoint($bp-charlie) {
				width: calc(50% - 3%);
				margin: 1.5%;
			}
			@include breakpoint($bp-bobby) {
				width: calc(25% - 1.5%);
				margin: .75%;
			}
		}
	}
}

.issues-grid {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 0px; /*Important for Firefox*/
	&.has-intro {
		padding-top: 25px;
		@include breakpoint($bp-charlie) {
			padding-top: 40px;
		}
		@include breakpoint($bp-bobby) {
			padding-top: 60px;
		}
	}
	&.has-buttons {
		padding-bottom: 20px;
		@include breakpoint($bp-charlie) {
			padding-bottom: 35px;
		}
		@include breakpoint($bp-bobby) {
			padding-bottom: 55px;
		}
	}
	@include breakpoint($bp-bobby) {
		text-align: center;
	}
	.single-issue {
		float: none;
		width: 100%;
		margin: 6px 0;
		border-bottom: 3px solid #c3c6c3;
		background: #FFF;
		border-radius: 0;
		box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.15);
		height: 275px;
		position: relative;
		@include hover-fade();
		overflow: hidden;

		@include breakpoint($bp-charlie) {
			width: calc(50% - 1rem);
			margin: 6px 0.5rem;
			height: 325px;
			float: none;
			display: inline-block;
		}
		@include breakpoint($bp-bobby) {
			height: 350px;
			width: calc(33.3% - 1rem);
		}

		//when visible, assume that items will be hovered and change
		//something related to this causes the sticky nav to jump around, which is crazy, not sure what the fix is
		.activeSection & {
			will-change: transform, background-color;
			p, small {
				&.teaser{
					will-change: transform, max-height, opacity;
				}
			}
		}

		&:hover {
			background: $mallard;
			transform: translateY(-6px) scale(1.1);
			z-index: 99;;
			border-bottom: 3px solid $mallard;
			@include box-shadow(6px, 9px, 12px, rgba(0,0,0,0.12));
			@include breakpoint($bp-bobby) {
				transform: translateY(-6px) scale(1.2);
			}
			.grid-image-container {
				background: $mallard;
				.grid-image {
					opacity: 0;
				}
			}
			.wrapper {
				h5, h6, p, small {
					color: $springwood;
				}
				h5 {
					margin: 0;
					@include hover-fade();
					@include transform(scale(0.8));
				}
				p, small {
					&.teaser {
						opacity: 1;
						position: relative;
						max-height: 275px;
						@include hover-fade();
						@include transform(translateY(0%) scale(0.8));
						color: $springwood;
						@include breakpoint($bp-charlie) {
							max-height: 325px;
						}

						@include breakpoint($bp-bobby) {
							max-height: 350px;
						}
					}
				}
				.social.links {
					@include transform(translateY(0%));
					opacity: 1;
				}
			}
		}
		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
		}
		&.has-image {
			.wrapper {
				h5, p, small {
					color: white;
				}
			}
		}
		.grid-image-container {
			height: 100%;
			background: $mineshaft;
			.grid-image {
				background-size: cover !important;
				background-position: 50% 50%;
				height: 100%;
				width: 100%;
				opacity: .6;
				@include hover-fade();
			}
			+.wrapper h5{
				color: $springwood;
			}
		}
		.wrapper {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%)); 
			z-index: 98;
			left: 0;
			right: 0;
			h5, h6 {
				text-align: center;
				margin: 0px;
				@include hover-fade();
			}
			h5 {
				padding: 0px 32px;
			}
			h5, p.teaser {
				color: $mineshaft;
			}
			small {
				display: block;
			}
			p, small {
				text-align: center;
				margin: 0;
				@include transition(0.2s);
				&.teaser {
					position: absolute;
					@include transform(translateY(20%));
					@include transition(0.15s);
					width: 100%;
					left: 0;
					right: 0;
					padding: 0px 16px;
					opacity: 0;
					line-height: 140%;
					max-height: 0;
					overflow: hidden;
				}
			}
			.social.links {
				width: auto;
				position: absolute;
				@include transform(translateY(50%));
				opacity: 0;
				@include hover-fade();
				bottom: 35px;
				z-index: 99;
				ul {
					position: relative;
					width: 100%;
					top: 8px;
					li {
						display: inline-block;
						float: left;
						font-size: 18px;
						.fa-facebook {
							font-size: 17px;
						}
						a {
							position: relative;
							padding: 2px 7px 0px;
							color: white;
							&:hover {
								color: $body;
							}
						}
						&:first-child {
							a {
								padding-left: 0px;
							}
						}
					}
				}
			}
		}
	}
}
