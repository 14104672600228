.main-site-footer {
	float: left;
	width: 100%;
	padding: 30px 0;
	color: $body;
	background: $mallard;
	@include breakpoint($bp-charlie) {
		padding: 6.35rem 0 3rem;
	}
	.col-md-offset-1{
		margin-top: 2rem;
		@include breakpoint($bp-charlie) {
			margin-top: 0;
		}
	}
	a {
		color: $body;
		&:hover {
			color: $secondary;
		}
	}

	.col-xs-12.col-md-4:not(.col-sm-6){
		@include breakpoint($bp-charlie) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 3rem;
			.heading__xs{
				flex: 0 0 auto;
				width: 100%;
			}
			.signup{
				flex: 0 0 auto;
				width: 50%;
			}
		}
		@include breakpoint($bp-bobby) {
			.signup{
				width: 100%;
			}
		}
	}
	&.submenu {
		padding: 40px 0 100px;
		@include breakpoint($bp-charlie) {
			padding: 40px 0;
		}
	}
	.secondary a,
	.btn{
		font-size: 11px;
		text-transform: uppercase;
		letter-spacing: 0.08em;
		color: white;
		max-width: 198px;
		min-width: 0;
		padding: 13px 15px;
		margin-bottom: .8rem; 
		border: 2px solid $colorado;
		&:hover{
			background: $secondary;
			border-color: $secondary;
			color: #fff;
		}
	}
	.secondary .outline a{
		background: $mallard;
		&:hover{
			background: $secondary;
			border-color: $secondary;
			color: #fff;
		}
	}
	nav {
		&.footer-secondary {
			width: 100%;
			margin: 0 0 10px 0;
			display: block;
			@include breakpoint($bp-charlie) {
				margin: 0 0 15px 0;
			}
			@include breakpoint($bp-bobby) {
				margin: 0 0 20px 0;
			}
			ul {
				margin: 0;
				text-align: right;
				line-height: 1.2;
				li {
					text-align: right;
					float: none;
					display: inline-block;
					margin: 0 0 0 15px;
					a{
						color: $camo;
						font-family: $primary-font;
						border-bottom: 1px solid $camo;
						padding-bottom: 3px;
						display: inline-block;
						font-weight: 400;
						text-transform: none;
						@include hover-fade();
						&:hover{
							color: $springwood;
							border-color: $springwood;
						}
					}
				}
			}
		}
		&.primary{
			float: none;
			@include breakpoint($bp-charlie) {
				margin-left: 14px;
			}
			ul{
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				height: 220px;
				max-height: 220px;
				li{
					padding: 0;
					font-family: $headline-font;
					font-size: 11px;
					text-transform: uppercase;
					letter-spacing: .08em;
					font-weight: 600;
					line-height: 1;
					margin: 0rem 0 1.5rem;
				}
				li .sub-menu{
					position: relative;
					top: auto;
					left: auto;
					opacity: 1;
					visibility: visible;
					margin: 1.25rem 0 0;
					padding: 0;
					transform: 0;
					background: transparent;
					&:before{
						display: none;
					}
					li{
						display: inline-block;
						margin: 0rem 0 0.85rem;
					}
					li a{
						color: $camo;
						letter-spacing: 0;
						border-bottom: 1px solid $camo;
						padding-bottom: 3px;
						font-weight: 400;
						font-size: 13px;
						text-transform: none;
						opacity: 0.8;
						&:hover{
							color: $springwood;
							border-color: $springwood;
							opacity: 1;
						}
					}
				}
			}
			a {
				color: $camo;
				&:hover{
					color: $springwood;
				}
			}
		}
		/*
		&.primary {
			float: left;
			width: 100%;
			display: block;
			margin-bottom: 20px;
			@include breakpoint($bp-charlie) {
				margin-bottom: 40px;
			}
			@include breakpoint($bp-bobby) {
				margin-bottom: 70px;
			}
			ul {
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;
				li {
					text-align: center;
					font-family: $headline-font;
					font-weight: 600;
					@include breakpoint($bp-charlie) {
						font-weight: 600;
					}
					&.home {
						display: block;
					}
					a {
						font-size: 18px;
						@include breakpoint($bp-charlie) {
							font-size: 19px;
						}
						@include breakpoint($bp-bobby) {
							font-size: 20px;
						}
					}
				}
			}
		}
		*/
	}
	.site-by {
		margin-top: 10px;
		text-align: right;
		font-weight: 400;
		margin-top: 35px;
		display: block;
		text-align: center;
		font-size: 13px;
		color: $camo;
		font-family: $headline-font;
		a {
			color: $camo;
			&:hover {
				color: $springwood;
			}
		}
	}
	.social {
		margin-top: 0;
		margin-bottom: 26px;
		&.links {
			float: left;
			width: 100%;
			ul {
				justify-content: flex-end;
				display: flex;
				float: none;
				li {
					margin-left: 14px;
					a {
						color: $camo;
						padding: 0;
						@include hover-fade();
						&:hover{
							color: $springwood;
						}
						i {
							font-size: 24px;
							@include breakpoint($bp-charlie) {
								font-size: 18px;
							}
							@include breakpoint($bp-bobby) {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

.footer-meta {
	float: left;
	width: 100%;
	.copyrights {
		margin: 12px 0 4px;
		text-align: right;
		color: $camo;
		float: none;
		width: 100%;
		font-family: $primary-font;
		display: block;
		line-height: 1.2;
		span {
			margin: 0;
		}
	}
	span {
		color: $footer;
		font-family: $headline-font;
		font-weight: 400;
		float: left;
		width: 100%;
		display: block;
		font-size: 13px;
		&.campaign-name {
			margin-top: 15px;
		}

		&.below-copyright {
			text-align: right;
			font-family: $headline-font;
			p {
				font-family: inherit;
				font-size: inherit;
				color: $camo;
				margin: 1em 0;
			}
		}
		&.paid-for {
			text-align: right;
			display: block;
			float: none;
			width: 100%;
			color: $camo;
			font-weight: 700;
			margin: 0;
			font-size: 13px;
			line-height: 1.2;
		}
	}
}
