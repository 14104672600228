/* Variables are only for the topper menus */

$solidBgColor	: $body;
$headlineColor	: #fff;
$paragraphColor	: #fff;

//Tall Topper Height
$tallDesktop	: 780px;
$tallTablet		: 540px;

//Short Topper Height
$shortDesktop	: 420px;
$shortTablet	: 420px;

.home .topper {
	height: auto!important;
	max-height: 100%;
	min-height: 150px;
	padding: 0 0 0 0;
	text-align: center;
	@include transition(all 0.3s ease-in-out);
	//margin-top: $header-mobile-height;
	@include breakpoint($bp-charlie) {
		//height: calc(100vh - 72px)!important;
		//max-height: 650px;
		min-height: 550px;
		//margin-top: $header-height;
	}
	@include breakpoint($bp-bobby) {
		height: calc(100vh - 36px)!important;
		max-height: 850px;
		min-height: 720px;
		display: flex;
		//margin-top: $header-height;
	}
	.panel-info{
		padding: 140px 1rem 70px;
		position: relative;
		@include breakpoint($bp-charlie) {
			padding: 200px 1rem;
		}
		@include breakpoint($bp-bobby) {
			flex: 0 0 auto;
			width: 50%;
			padding: 7.5rem 3rem 1rem;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
		&__h1{
			flex: 0 0 auto;
			width: 100%;
			line-height: 1.1;
			font-size: 40px;
			margin-bottom: 0.65em;
			letter-spacing: -0.01em;
			max-width: 500px;
			margin: 0 auto 0.65em;
			@include breakpoint($bp-charlie) {
				font-size: 54px;
			}
			span{
				margin: 0;
				position: relative;
				display: inline-block;
				padding: 0 .15em;
				margin-left: -.2125em;
			}
			span:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0.025em;
				display: inline-block;
				height: .15em;
				width: 100%;
				background: rgba(112,159,95,.5);
				z-index: -9;
				margin-top: 3px;
				margin-left: 0;
			}
		}
		&__p{
			flex: 0 0 auto;
			width: 100%;
			margin: 0 auto;
			max-width: 320px;
		} 
		&__p p{
			font-size: 15px;
			color: #c1bcb6;
			line-height: 1.5;
			@include breakpoint($bp-charlie) {
				font-size: 16px;
				line-height: 1.5625;
			}
		}
		&__cta{
			flex: 0 0 auto;
			width: 100%;
			margin-top: 2rem;
			@include breakpoint($bp-charlie) {
				margin-top: 3.5rem;
			}
		}
		&__btn{
			max-width: 260px;
			min-width: 260px;
			min-height: 48px;
			width: 50%;
			background: $colorado;
			border-radius: 5em;
			font-size: 12px;
			letter-spacing: 0.08em;
			font-family: $headline-font;
			text-transform: uppercase;
			font-weight: 600;
			cursor: pointer;
			@include hover-fade();
			&:hover{
				background: $newmexico;
			}
		}
		> * {
			z-index: 9;
			position: relative;
		}
		&__overlay{
			background: #101d0f;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.5;
			z-index: 1;
		}
	}
	.panel-state{
		background: $mallard;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 3rem 1rem;
		@include breakpoint($bp-bobby) {
			flex: 0 0 auto;
			width: 50%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			padding: 12rem 4rem 1rem;
		}
		&__heading{
			font-size: 19px;
			font-family: $primary-font;
			color: $bg-light;
			font-weight: 400;
			flex: 0 0 auto;
			width: 100%;
			margin: 0 auto 1.65em;
			line-height: 1.5;
			max-width: 420px;
			@include breakpoint($bp-charlie) {
				font-size: 23px;
			}
			em{
				font-weight: 400 !important;
				font-style: italic !important;
			}
		}
		&__group{
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			flex: 0 0 auto;
			width: 100%;
			max-width: 500px;
			align-items: center;
			margin: 0 auto;
		}
		&__fig{
			position: relative;
			svg,
			img{
				max-width: 175px;
				height: auto;
				width: 100%;
			}
		}
		&__caption{
			position: absolute;
			font-family: $headline-font;
			font-weight: 600;
			color: $bg-light;
			top: 50%;
			left: 50%;
		}
		&__link{
			flex: 0 0 auto;
			width: 33%;
			padding: 0;
			@include hover-fade();
			&:hover{
				@include transform(scale(1.2));
			}
			@include breakpoint($bp-charlie) {
			}
		}
		&__img{
			width: 100%;
		}
		&__label{
			display: none;
		}
	}
}

.topper__mobile-image {
	background-color: $mallard;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@include breakpoint($bp-charlie) {
		display: none;
	}
}

.topper {
	background-color: $mallard;
	background-size: cover;
	background-position: bottom left;
	background-repeat: no-repeat;
	float: left;
	width: 100%;
	position: relative;
	padding: 40px 0 40px;
	//margin-top: $header-mobile-height;
	float: none;
	@include breakpoint($bp-charlie) {
		padding: 0 0 0 0;
		//margin-top: $header-height;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	h6 {
		margin-bottom: 10px;
	}
	h1 {
		margin-bottom: 25px;
	}
	p {
		color: $paragraphColor;
	}
	.topper__mobile-image.-color + .overlay {
		display: none;
		@include breakpoint($bp-charlie) {
			display: block;
		}
	}
	&__main{
		position: relative;
		&:after{
			content: "";
			width: 220px;
			height: 3px;
			position: absolute;
			bottom: 0;
			left: 50%;
			background: #c1bcb6;
			transform: translateX(-50%);
			@include breakpoint($bp-charlie) {
				width: 460px;
			}
		}
	}
	.container {
		height: 100%;
		max-width: 680px;
		// set to 0 so it kicks in with animations. if animation is removed, remove this opacity

		.js & {
			opacity: 0;
		}
		.row {
			height: 100%;
		}
	}
	&.target{
		background: $springwood;
		text-align: center;
		height: auto;
		padding: 120px 1rem 0;
		@include breakpoint($bp-charlie) {
			//height: $tallTablet;
			padding: 155px 1rem 0;
		}
		@include breakpoint($bp-bobby) {
			//height: calc(100vh - 74px);
			//min-height: 600px;
			padding: 155px 0 0;
		}
		&.montana{
			.bills__category{
				color: $montana;
			}
			.bills__tags a:hover{
				background: $montana;
				border-color: $montana;
			}
		}
		&.newmexico{
			.bills__category{
				color: $newmexico;
			}
			.bills__tags a:hover{
				background: $newmexico;
				border-color: $newmexico; 
			}
		}
		&.arizona{
			.bills__category{
				color: $arizona;
			}
			.bills__tags a:hover{
				background: $arizona;
				border-color: $arizona;
			}
		}
		.target__fig{
			width: 170px;
			height: 170px;
			margin: 0 auto 28px;
			position: relative;
			border-radius: 50%;
			overflow: hidden;
		}
		.target__img{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			height: auto;
			min-height: 170px;
			display: block;
			min-width: 170px;
			transform: translate(-50%, -50%);
		}
		.target__small{
			color: $mineshaft;
			margin-bottom: 0;
		}
		.target__large{
			color: $mineshaft;
			max-width: 700px;
			font-size: 28px;
			line-height: 1.225;
			margin: 0 auto;
			padding-bottom: 24px;
			@include breakpoint($bp-charlie) {
				font-size: 36px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 44px;
			}
		}
		.target__deck{
			font-size: 14px;
			max-width: 630px;
			margin: 0 auto;
			position: relative;
			padding-bottom: 30px;
			@include breakpoint($bp-charlie) {
				font-size: 15px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 16px;
			}
		}
		.target__deck p{
			color: $mineshaft;
			font-size: 16px;
		}
	}
	&.tall .container{
		@include breakpoint($bp-charlie) {
			max-width: 1230px;
		}
		.vertical-centered{
			@include breakpoint($bp-charlie) {
				text-align: left;
				max-width: 680px;
				top: 100%;
				transform: translateY(-100%);
			}
			@include breakpoint($bp-bobby) {
				margin-left: 120px;
			}
		}
	}
	.donation {
        margin: 15px 0 0 0;
        @include breakpoint($bp-charlie) {
            margin: 20px 0 0 0;
        }
        .donation-note {
            color: white;
        }
        .col-lg-8 {
            margin: 0 auto;
            float: none;
        }
        ul {
            li {
                font-weight: 700;
            }
        }
    }
	.deck {
		margin-bottom: 25px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 0;
		}
		p {
			margin-bottom: 20px;
			font-size: 16px;
			color: #c1bcb6;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	&.short {
		height: auto;
		padding: 120px 0.5rem 55px;
		.deck {
			margin-bottom: 0px;
		}
		@include breakpoint($bp-charlie) {
			height: $shortTablet;
			padding: 110px 0 55px 0;
		}
		@include breakpoint($bp-bobby) {
			height: $shortDesktop;
		}
	}
	&.tall {
		height: auto;
		padding: 120px 0.5rem 50px;
		@include breakpoint($bp-charlie) {
			height: $tallTablet;
			padding: 85px 0 100px 0;
		}
		@include breakpoint($bp-bobby) {
			height: 640px;
		}
		.page-title{
			letter-spacing: -.01em;
			text-indent: -0.075em;
			line-height: 1;
			display: flex;
			flex-wrap: wrap;
			text-align: center;
			justify-content: center;
			@include breakpoint($bp-charlie) {
				text-align: left;
				justify-content: flex-start;
			}
			@include breakpoint($bp-bobby) {
				font-size: 96px;
			}
			span{
				margin: 0;
				position: relative;
				display: inline-block;
				padding: 0 0.15em;
			}
			span:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0.025em;
				display: inline-block;
				height: .15em;
				width: 100%;
				background: rgba(112,159,95,.5);
				z-index: -9;
				margin-top: 3px;
				margin-left: 0;
			}
		}
		.deck {
			display: none;
			@include breakpoint($bp-charlie) {
				display: block;
			}
		}
	}
	&.home {
		height: auto;
		padding: 0;
		@include breakpoint($bp-charlie) {
			height: $tallTablet;
			padding: 0 0 105px;
		}
		@include breakpoint($bp-bobby) {
			height: $tallDesktop;
			padding: 0 0 0 0;
		}
		.deck {
			display: block;
			@include breakpoint($bp-charlie) {
				display: block;
			}
		}
	}
	.read-more {
		position: absolute;
		bottom: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 47px;
		@include breakpoint($bp-bobby) {
			max-width: 60px;
			bottom: 35px;
		}
		a {
			padding: 10px;
			float: left;
			width: 100%;
			height: auto;
			color: #fff;
			svg {
				position: relative;
				width: 100%;
				@include hover-fade();
			}
			&:hover {
				color: $secondary;
				svg {
					@include transform(scale(1.15));
				}
			}
		}
	}
	.bills__category{
		position: relative;
		top: auto;
		right: auto;
		transform: none;
		border: 1px solid transparent;
		font-size: 15px;
		@include breakpoint($bp-bobby) {
			top: auto;
			right: auto;
		}
	}
	.bills__tags{
		margin-top: 0;
		padding-bottom: 3rem;
	}
}

.topper-posts {
	padding: 110px 0 20px;
	@include breakpoint($bp-charlie) {
		padding: 100px 0 0 0;
	}
	@include breakpoint($bp-bobby) {
		padding: 140px 0 40px;
	}
	.col-md-8 {
		margin: 0 auto;
		float: none;
	}
	&.simple-topper {
		.col-md-8 {
			display: table;
			margin: 0 auto 0 auto;
		}
		h6, h1 {
			text-align: center;
		}
	}
	.page-meta {
		text-align: center;
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 15px;
		a.tag {
			margin: 0 .67em 5px .67em;
			padding: .8em 1em;
			font-family: aktiv-grotesk,sans-serif;
			font-size: 11px;
			font-weight: 700;
			color: #96928d;
			text-transform: uppercase;
			letter-spacing: .08em;
			line-height: 1.1;
			border: 1px solid #c1bcb6;
		}
		a:hover.tag {
			background: #96928d;
			border-color: #96928d;
			color: $springwood;
		}
		a {
			color: $body;
			&:hover {
				color: $secondary;
			}
		}
	}
	h1, h2 {
		margin-bottom: 0px;
	}
	h2 {
		margin: 0 auto 0.67em auto;
		max-width: 700px;
		font-weight: 600;
		color: #333;
		text-align: center;
	}
	.post-info {
		text-align: center;
		font-size: 18px;
		margin-top: 15px;
	}
}

// Topper Call To Action Area

.topper-cta {
	float: left;
	width: 100%;
	.btn {
		margin: 0 auto;
		display: table;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.topper-form {
		margin: 0 auto;
		display: block;
		float: none;
		width: 100%;
		padding: 0;
		@include breakpoint($bp-adams) {
			width: 300px;
		}
		form {
			.first-name,
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

// Topper Video Background

.topper-video {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -100;
	background-size: cover !important;
}

.fullscreen-bg__video {
	display: none;
	@include breakpoint($bp-charlie) {
  		display: block;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

// Topper Menu

.sub-nav {
	width: 100%;
	margin: 14px 0;
	text-align: center;
	float: none;
	@include breakpoint($bp-charlie) {
		margin: 34px 0 4px;
	}
	@include breakpoint($bp-bobby) {
		padding: 0 40px;
		margin: 34px 0 34px;
	}
	.container {
		padding: 0;
		max-width: 860px;
		width: 100%;
	}
	.social {
		margin: 0;
		float: right;
		display: none;
		height: 70px;
		padding: 21px 30px;
		@include breakpoint($bp-bobby) {
			display: block;
		}
		span {
			margin: 0 10px 0;
			font-family: $headline-font;
			color: #666666;
			text-transform: none;
			font-weight: 600;
			font-size: 14px;
		}
		ul {
			li {
				a {
					padding: 0px;
					color: #666666;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
	.topper-menu {
		float: left;
		width: 100%;
		display: block;
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			display: block;
			width: auto;
			float: none;
			margin: 0 auto;
			display: table;
		}
		&.news {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
	}
	nav {
		margin-top: 15px;
		margin-bottom: 15px;
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			margin-top: 0;
			margin-bottom: 0;
		}
		ul {
			margin: 0px;
			padding: 0px;
			display: inline-block;
			li {
				width: 100%;
				line-height: 1;
				margin: 0.4em 0;
				@include breakpoint($bp-charlie) {
				width: auto;
				display: inline-block;
				}
				&.current-menu-item {
					a {
						color: $mineshaft;
						&:after {
							opacity: 1;
							border-bottom: 4px solid rgba(112,159,95,.5);
						}
					}
					&.arizona a:after{
						border-bottom: 4px solid rgba($arizona,.5);
					}
					&.colorado a:after{
						border-bottom: 4px solid rgba($colorado,.5);
					}
					&.montana a:after{
						border-bottom: 4px solid rgba($montana,.5);
					}
					&.nevada a:after{
						border-bottom: 4px solid rgba($nevada,.5);
					}
					&.new-mexico a:after{
						border-bottom: 4px solid rgba($newmexico,.5);
					}
					&.utah a:after{
						border-bottom: 4px solid rgba($utah,.5);
					}
				}
				.children {
					display: none;
				}
				a {
					font-size: 16px;
					font-weight: 600;
					font-family: $headline-font;
					display: inline-block;
					text-align: center;
					color: #c1bcb6;
					position: relative;
					margin: 0 11px;
					padding: 0;
					line-height: 1;
					@include hover-fade();
					@include breakpoint($bp-charlie) {
						padding: 0;
					}
					&:after {
						content: '';
						opacity: 0;
						position: absolute;
						left: 0;
						bottom: -2px;
						display: inline-block;
						height: 0.25em;
						width: 100%;
						z-index: 0;
						border-bottom: 4px solid transparent;
						@include hover-fade();
					}
					&:hover {
						color: $mineshaft;
						&:after {
							opacity: 1;
							border-bottom: 4px solid rgba(112,159,95,.5);
						}
					}
				}
				&.arizona a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($arizona,.5);
					}
				}
				&.colorado a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($colorado,.5);
					}
				}
				&.montana a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($montana,.5);
					}
				}
				&.nevada a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($nevada,.5);
					}
				}
				&.new-mexico a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($newmexico,.5);
					}
				}
				&.utah a:hover{
					&:after {
						opacity: 1;
						border-bottom: 4px solid rgba($utah,.5);
					}
				}
			}
		}
	}
}

// State Specific
.arizona{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($arizona,.5);
			}
		}
	}
}
.colorado{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($colorado,.5);
			}
		}
	}
}
.montana{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($montana,.5);
			}
		}
	}
}
.nevada{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($nevada,.5);
			}
		}
	}
}
.new-mexico{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($newmexico,.5);
			}
		}
	}
}
.utah{
	.topper.tall {
		.page-title{
			span:after {
				background: rgba($utah,.5);
			}
		}
	}
}

.news__category {
    padding: .8em 1em;
    text-transform: uppercase;
    font-family: aktiv-grotesk,sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.1;
	letter-spacing: .08em;
	transition: .35s;
}

a.arizona {
	color: $arizona!important;
	transition: .35s;
}

a.colorado {
	color: $colorado!important;
	transition: .35s;
}

a.montana {
	color: $montana!important;
	transition: .35s;
}

a.nevada {
	color: $nevada!important;
	transition: .35s;
}

a.new-mexico {
	color: $newmexico!important;
	transition: .35s;
}

a.utah {
	color: $utah!important;
	transition: .35s;
}

a:hover.arizona,
a:hover.colorado,
a:hover.montana,
a:hover.nevada,
a:hover.new-mexico,
a:hover.utah {
	color: #a5633b!important;
	transition: .35s;
}
.issue-tags {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
    justify-content: center;
	@include breakpoint($bp-charlie) {
		flex-direction: row;
	}
}
hr.divider {
	content: "";
	margin: 3em auto 0 auto;
	background: #c1bcb6;
    width: 200px;
	height: 3px;
	@include breakpoint($bp-bobby) {
		width: 450px;
	}
}