$beat-one: 0.5s;
$beat-two: 0.8s;
$beat-three: 1.2s;

$animation-breakpoint: 968px;// see animations.js, which should match this

%will-animate-in-via-opacity{
  @include breakpoint($animation-breakpoint) {
    .js & {
      opacity: 0;
      will-change: opacity;
    }
    //animations.js doesn't fire events on resize, so this ensures items will be visible when going from thin to wide widths
    .js.was-resized & {
      opacity: 1;
    }
  }
}

//sub-items that should fade to full opacity in sequence
.issues-grid .single-issue, .news-items .news-item{
  @extend %will-animate-in-via-opacity;
}

.topper {
  h1, .panel-info__btn, h6, .deck, .target__deck, .bills__tags, .bills__sponsor{
    @extend %will-animate-in-via-opacity;
  }
}


.bills__sponsor{
  animation-delay: .5s;
}