input[type="text"], input[type="email"], textarea {
	background: #fff;
	height: 45px;
	padding: 10px 20px;
	border-radius: 0;
	@include transition(background 0.25s ease);
	float: left;
	width: 100%;
	outline: none;
	border: 1px solid #FFF;
	color: $body;
	font-weight: 700;
	font-size: 13px;
	font-family: $headline-font;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	&:focus {
		background: #eee;
	}
	&::-webkit-input-placeholder {
	  color: $body;
	}
	&::-moz-placeholder {
	  color: $body;
	}
	&:-ms-input-placeholder {
	  color: $body;
	}
	&:-moz-placeholder {
	  color: $body;
	}
}

input[type="submit"] {
	background: $newmexico;
	font-family: $headline-font;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 700;
	height: 45px;
	color: white;
	width: 100%;
	letter-spacing: 0.1em;
	cursor: pointer;
}

button {
	&.btn {
		float: left;
		width: 100%;
	}
}

body .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
	max-width: 100%!important;
	width: 100%!important;
}

.action-form {
	#action_info, #logo_wrap {
		display: none;
	}
	#can_embed_form {
		background-color: transparent !important;
		padding: 0px !important;
		border: none !important;
	}
	#can_embed_form {
		#can_embed_form_inner {
			h1,h2,h3,h4,h5,h6,p {
				display: none;
			}
		}
		.country_wrap {
			display: none;
		}
		#d_sharing {
			display: none !important;
		}
		input {
			margin-bottom: 4px !important;
		}
		input[type="text"], input[type="password"], input[type="url"], input[type="email"], input[type="tel"], input[type="number"], textarea {
			background: #fff !important;
			height: 50px !important;
			padding: 10px 20px !important;
			border-radius: 3px !important;
			@include transition(background 0.25s ease !important) ;
			outline: none !important;
			border: 1px solid #ccc !important;
			color: $body !important;
			font-size: 20px !important;
			font-family: $primary-font !important;
			@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
			&:focus {
				background: #eee !important;
			}	
			&::-webkit-input-placeholder {
	  			color: $body !important;
			}
			&::-moz-placeholder {
	  			color: $body !important;
			}
			&:-ms-input-placeholder {
	  			color: $body !important;
			}
			&:-moz-placeholder {
	  			color: $body !important;
			}
		}
		input[type="submit"] {
			display: table !important;
			@include hover-fade();
			cursor: pointer !important;
			background: $primary !important;
			color: #fff !important;
			padding: 17px 15px !important;
			border-radius: 3px !important;
			line-height: 120% !important;
			min-width: 300px !important;
			max-width: 350px !important;
			width: auto !important;
			font-size: 16px !important;
			font-weight: 600 !important;
			font-family: $headline-font !important;
			text-shadow: none !important;
			text-align: center;
			margin: 11px auto 0px !important;
			@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
			&:hover {
				background: $secondary !important;
				color: #fff !important;
			}
			
		}
	}
}

.content {
	.gform_wrapper {
		margin-top: 35px;
		.gform_body {
			width: 100%!important;
		}
		.gfield_label {
			margin-top: 0!important;
    		font-family: $headline-font; 
			color: $headlineColor;
			font-weight: 600;
			font-size: 16px;
			line-height: 130%;
			margin: 0 0 .5em;
			@include breakpoint($bp-charlie) {
				font-size: 18px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 20px;
			}
		}
		.validation_error {
			color: #FF3232;
		    font-size: 16px;
		    font-weight: 600;
		    margin-bottom: 25px;
		    border: 1px solid #FF3232;
		    padding: 10px 0;
		    clear: both;
		    width: 100%;
		    text-align: center;
		}
		li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
			background: transparent;
			border-top: none;
    		border-bottom: none;
    		.gfield_label, .gfield_required {
    			color: $body;
    		}
    		.gfield_label {
    			margin-top: 0!important;
    			font-family: $headline-font; 
				color: $headlineColor;
				font-weight: 600;
				font-size: 16px;
				line-height: 130%;
				margin: 0 0 .5em;
				@include breakpoint($bp-charlie) {
					font-size: 18px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 20px;
				}
    		}
    		.gfield_description.validation_message {
    			margin-top: 0;
    			padding-top: 0;
    			color: #FF3232;
    		}
    		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
    			border: 1px solid #FF3232!important;
    		}
		}
		#gform_ajax_spinner_1 {
			margin: 15px auto;
    		float: none;
    		display: table;
    		max-width: 25px!important;
    		max-height: 25px!important
		}
		ul {
			&.gform_fields {
				li {
					&.gfield {
						padding-right: 0px;
						float: left;
						width: 100%;
						max-width: 100%;
						margin: 0 0 20px;
					}
					input[type="text"], input[type="email"], textarea {
						border: 1px solid #ccc;
					}
				}
			}
		}
		.gform_footer {
			margin: 0px;
			padding: 0px;
			input[type="submit"] {
				display: table;
				width: auto;
				@include hover-fade();
				cursor: pointer;
				background: $primary;
				color: #fff;
				padding: 17px 15px;
				border-radius: 3px;
				line-height: 120%;
				min-width: 300px;
				max-width: 350px;
				width: auto;
				font-size: 16px;
				font-weight: 600;
				font-family: $headline-font;
				text-align: center;
				margin: 0 auto;
				@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
				&:hover {
					background: $secondary;
					color: #fff;
				}
			}
		}
	}
}

.signup{
	display: flex;
	width: 100%;
	margin-bottom: 1.75rem;
	&__email{
		flex: 0 0 auto;
		width: 63.5%;
	}
	&__submit{
		flex: 0 0 auto;
		width: 36.5%;
	}
}

body.page-template-default .gform_wrapper .top_label div.ginput_container{
	margin-top: 0;
}

body .gform_wrapper .top_label .gfield_label{
	margin-bottom: 0;
}

.gform_footer input[type=submit]{
	height: auto;
}

body .content .gform_wrapper .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning .gfield_description.validation_message, 
body .content .gform_wrapper li.gfield.gfield_error .gfield_description.validation_message{
  color: #cd5c45;
}

.content .gform_wrapper .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning .gform_wrapper li.gfield_error textarea, 
.content .gform_wrapper .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), 
.content .gform_wrapper li.gfield.gfield_error .gform_wrapper li.gfield_error textarea, .content .gform_wrapper li.gfield.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
	border: 1px solid #cd5c45!important;
}

body .content .gform_wrapper .validation_error {
	color: #cd5c45;
	border: 1px solid #cd5c45;
}

body .content .gform_wrapper .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning .gfield_label, 
body .content .gform_wrapper li.gfield.gfield_error .gfield_label{
	margin-bottom: 0;
}

body .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container, 
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
	margin-top: 0;
}

div#gform_confirmation_wrapper_1 {
	border: 1px solid #689659;
	color: hsla(105, 26%, 37%, 1);
	background: white;
	box-shadow: 4px 4px 4px rgba(0,0,0,.08);
	padding: 1rem;
	text-align: center;
}

// NGP
#ngp-header-form-123{
	width: 100%;
}
.ngp-form {
  &.at {
    overflow: visible;
  }
  .at-inner {
    background-color: transparent !important;
    border: none !important;
    margin-top: 10px !important;
  }
  .at-title {
    display: none;
  }
  .at-fieldset {
    padding: 0px !important;
  }
  .at-fields {
    label {
		line-height: 17px !important;
      font-weight: 700;
	font-size: 13px;
	font-family: $headline-font !important;
      text-transform: uppercase;
      color: #fff;
      min-width: 100%;
    }
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea {
      background: #fff;
      border-radius: $borderRadius;
    //   border: 2px solid #fff;
	line-height: 130%;
      color: $body;
	font-weight: 700;
	font-size: 13px;
	font-family: $headline-font;
      padding: 13px 14px 13px;
      outline: none;
      transition: background 0.25s ease, color 0.25s ease;
      width: 100%;
      margin-top: 2px;
      margin-bottom: 15px;
      height: auto;
      &:focus {
        background: #fff;
        color: $primary;
        border-color: #fff;
        box-shadow: none;
	  }
	  &::-webkit-input-placeholder {
			color: $body !important;
		}
		&::-moz-placeholder {
			color: $body !important;
		}
		&:-ms-input-placeholder {
			color: $body !important;
		}
		&:-moz-placeholder {
			color: $body !important;
		}
    }
    label {
      margin: 0px;
      font-family: $primary-font;
      line-height: 1;
      margin-top: 0px;
      u {
        text-decoration: none;
      }
    }
  }
  // Re-arrange the fields; NGP uses Flexbox in the form, so Flexbox is safe to use here
  .at-fields {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .at-row.PostalCode {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .at-row.EmailAddress {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .at-markup {
    &.HeaderHtml {
      display: none;
    }
    &.SmsLegalDisclaimer {
      &.at-legal {
        margin: 0px;
        margin-top: 10px;
        p {
          font-size: 11px;
        }
      }
    }
    &.FooterHtml {
      display: none;
    }
  }
  .at-form-submit {
    padding: 0px;
	text-align: center;
	
	.at-submit{
		background: $newmexico !important;
		border-radius: 0;
		margin: 0 0 1.75rem !important;
		font-family: $headline-font !important;
		text-transform: uppercase !important;
		font-size: 11px !important;
		font-weight: 700 !important;
		height: 45px !important;
		color: white !important;
		letter-spacing: 0.1em !important;
		@include hover-fade();

		&:hover, 
		&:focus{
			background: $secondary !important;
		}
	}
  }
  .error {
    padding: 0px !important;
    background: transparent !important;
    position: relative !important;
    top: 0px !important;
    margin-bottom: 15px !important;
    small.error {
      position: absolute !important;
      top: auto !important;
      bottom: -17px;
      right: 0px;
    }
  }
  legend {
    display: none;
  }
  .thankYou p{
	color: #fff;
	font-family: $headline-font !important;
	font-size: 16px;
	font-weight: 700 !important;
  }
  .ngp-form-view {
    .ngp-form-inner {
      form.control-group {
        .form-item {
          float: left;
          width: 100%;
          margin-bottom: 7px;
          &:last-of-type {
            margin-bottom: 12px;
          }
          label {
            color: $body;
            font-size: 22px;
            font-family: $primary-font;
            display: none;
          }
          input {
            margin: 0;
          }
        }
      }
    }
  }
}
