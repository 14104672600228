.home-about {
	float: none;
	background: white;
	margin: 0 auto 4rem;
	width: calc(100% - 1rem);
	max-width: 1145px;
	box-shadow: 15px 20px 40px rgba(0,0,0,0.05);
	border-bottom: 3px solid #c3c6c3;
	background: #fff;
	clear: both;
	padding: 50px 0;
	@include breakpoint($bp-charlie) {
		padding: 85px 0;
		width: calc(100% - 5rem);
	}
	@include breakpoint($bp-bobby) {
		padding: 125px 0;
	}
	.container{
		padding: 0;
	}
	.row {
    display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	&__img{
		margin-bottom: 2rem;
		margin-left: auto;
		margin-right: auto;
		max-width: 450px;
		display: block;
	}
	&__content{
		padding: 0 3rem 0 1rem; 
	}
	&__brow{
		text-transform: uppercase;
		letter-spacing: 0.08em;
		font-weight: 700;
		display: block;
		font-family: $headline-font;
		font-size: 12px;
		color: $mallard;
		@include breakpoint($bp-charlie) {	
			font-size: 13px;
		}
	}
	h2 {
		margin-bottom: 16px;
		margin-top: 0;
		font-family: $primary-font;
		font-size: 27px;
		line-height: 30px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 25px;
		}
	}
	&__img{
		width: 100%;
		padding: 0 4rem;
	}
	.description {
		margin-bottom: 20px;
		margin-top: 0;
		p {
			font-size: 16px;
			line-height: 24px;
			@include breakpoint($bp-charlie) {	
				font-size: 18px;
				line-height: 27px;
			}
		}
	}
	&__link{
		color: $mineshaft;
		font-family: $headline-font;
		letter-spacing: 0.08em;
		font-weight: 700;
		font-size: 11px;
		text-transform: uppercase;
		svg {
			width: 10px;
			height: 10px;
			position: relative;
			top: -1px;
			margin-left: 4px;
		}
	}
	.read-more {
		//float: left;
		width: 100%;
		a {
			float: none;
			display: table;
			//margin: 0 auto;
		}
	}
}

.home-issues {
	width: 100%;
	background: $springwood;
	padding: 0px 8px;
	@include breakpoint($bp-charlie) {
		padding: 35px 40px 85px;
	}
	@include breakpoint($bp-bobby) {
		padding: 0 40px;
	}
	.col-md-8 {
		float: none;
		display: table;
		margin: 0 auto;
	}
	.container {
		padding: 0;
		width: 100%;
		max-width: 1145px;
	}
	h3 {
		text-align: center;
		margin-bottom: 25px;
		margin-top: 0;
	}
	.description {
		margin-top: 0;
		p {
			text-align: center;
			margin-bottom: 0px;
		}
	}
	.read-more {
		float: left;
		width: 100%;
		a {
			float: none;
			display: table;
			margin: 0 auto;
		}
	}
}