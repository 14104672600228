.bills{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	&__single{
		flex: 0 0 auto;
		width: 100%;
		position: relative;
		background: white;
		padding: 1rem;
		margin-bottom: 25px;
		@include box-shadow(5px, 12px, 40px, 5px, rgba(0,0,0,0.05));
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		@include hover-fade;
		&:hover{
			background: $mallard;
			transform: scale(1.05);
			color: $springwood;
			.bills__heading a{
				color: white;
			}
			.bills__excerpt p{
				color: white;
			}
		}
		@include breakpoint($bp-bravo) {
			padding: 2rem;
		}
		@include breakpoint($bp-charlie) {
			width: calc(50% - 1rem);
			padding: 2rem 3rem 2rem 1.5rem;
		}
		@include breakpoint($bp-bobby) {
			width: calc(50% - 1.5rem);
			padding: 3rem 6.5rem 3rem 3.5rem;
			margin-bottom: 58px;
		}
		&:after{
			content: "";
			top: 0;
			right: 0;
			position: absolute;
			display: block;
			opacity: 0.25;
			width: 4px;
			height: 100%;
		}
		&.montana{
			&:after{
				background: $montana;
			}
			.bills__category{
				color: $montana;
				@include hover-fade;
				&:hover{
					background: $montana;
					border-color: $montana;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $montana;
						color: $springwood;
						border-color: $montana;
					}
				}
			}
		}
		&.newmexico{
			&:after{
				background: $newmexico;
			}
			.bills__category{
				color: $newmexico;
				@include hover-fade;
				&:hover{
					background: $newmexico;
					border-color: $newmexico;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $newmexico;
						color: $springwood;
						border-color: $newmexico;
					}
				}
			}
		}
		&.colorado{
			&:after{
				background: $colorado;
			}
			.bills__category{
				color: $colorado;
				@include hover-fade;
				&:hover{
					background: $colorado;
					border-color: $colorado;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $colorado;
						color: $springwood;
						border-color: $colorado;
					}
				}
			}
		}
		&.nevada{
			&:after{
				background: $nevada;
			}
			.bills__category{
				color: $nevada;
				@include hover-fade;
				&:hover{
					background: $nevada;
					border-color: $nevada;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $nevada;
						color: $springwood;
						border-color: $nevada;
					}
				}
			}
		}
		&.utah{
			&:after{
				background: $utah;
			}
			.bills__category{
				color: $utah;
				@include hover-fade;
				&:hover{
					background: $utah;
					border-color: $utah;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $utah;
						color: $springwood;
						border-color: $utah;
					}
				}
			}
		}
		&.arizona{
			&:after{
				background: $arizona;
			}
			.bills__category{
				color: $arizona;
				@include hover-fade;
				&:hover{
					background: $arizona;
					border-color: $arizona;
					color: $springwood;
					box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				}
			}
			.bills__tags{
				a{
					&:hover{
						background: $arizona;
						color: $springwood;
						border-color: $arizona;
					}
				}
			}
		}
	}
	.full-link {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 99;
	}
	&__excerpt{
		font-size: 14px;
		line-height: 21px;
		flex: 0 0 auto;
		padding-right: 2rem;
		width: 100%;
		@include breakpoint($bp-charlie) {
			font-size: 15px;
			line-height: 23px;
			padding-right: 0;
		}
		@include breakpoint($bp-bobby) {
			font-size: 16px;
			line-height: 25px;
		}
	}
	&__category{
		padding: .8em 1em;
		border: 1px solid transparent;
		text-transform: uppercase;
		font-family: $headline-font;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.1;
		letter-spacing: 0.08em;
	}
	&__catWrap{
		position: absolute;
		top: 3.35rem;
    right: -2.35rem;
		transform: rotate(90deg);
		z-index: 101;
		min-width: 120px;
		@include breakpoint($bp-charlie) {
			top: 3.25rem;
			font-size: 13px;
		}
		@include breakpoint($bp-bobby) {
			top: 4.2rem;
			right: -4px;
		}
	}
	&__heading{
		text-align: left!important;
		font-family: $primary-font;
		font-weight: 800;
		padding-right: 2rem;
		font-size: 18px;
		color: $mineshaft;
		margin-bottom: 0.55em !important;
		flex: 0 0 auto;
		width: 100%;
		@include breakpoint($bp-charlie) {
			font-size: 23px;
			margin-bottom: 0.95em !important;
		}
		a{
			color: $mineshaft;
		}
	}
	&__sponsor{
		font-family: $headline-font;
		font-size: 13px;
		font-weight: 500;
		padding: 0 2rem 0 0;
		line-height: 1.4;
		margin-bottom: 2px;
		flex: 0 0 auto;
		width: 100%;
		@include breakpoint($bp-charlie) {
			font-size: 14px;
			line-height: 1.5;
			margin-bottom: 4px;
		}
		@include breakpoint($bp-bobby) {
			font-size: 15px;
			margin-bottom: 2px;
		}
	}
	&__tags{
		margin: 0;
		position: relative;
		z-index: 101;
		margin-top: 1.5rem;
		flex: 0 0 auto;
		width: 100%;
		@include breakpoint($bp-charlie) {
			margin-top: 3rem;
		}
		a{
			font-size: 9px;
			display: inline-block;
			text-transform: uppercase;
			color: #96928d;
			font-family: $headline-font;
			font-weight: 700;
			border: 1px solid #c1bcb6;
			line-height: 1.1;
			padding: .8em 1.5em;
			letter-spacing: 0.08em;
			margin-bottom: 5px;
			@include hover-fade();
			@include breakpoint($bp-charlie) {
				padding: .8em 1em;
				font-size: 11px;
			}
			&:hover{
				background: #96928d;
				border-color: #96928d;
				//box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
				color: $springwood;
			}
		}
	}
}

.bills-page{
	.container {
		padding: 0 1rem;
		width: 100%;
		max-width: 1145px;
	}
}