::-moz-selection { background: $secondary; color:#fff; }
::selection { background: $secondary; color:#fff; }

body, html {
	background-color: $mallard!important;
    height: 100%;
    color: $body;
    //@include transition(all 0.3s ease-in-out);
	-webkit-font-smoothing: antialiased;//this is really performance intensive....
    main {
        background: $bg-light;
        margin-top: -86px;
    @include breakpoint($bp-charlie) {
        margin-top: -110px;
    }
    }
    &.search-open {
       // margin-top: 61px;
        header {
        }
        .topper, .topper-home {
            //top: 61px;
        }
        .top-search {
            top: 0;
            input[type="text"] {
                opacity: 1;
            }
        }
    }
}

body.home main {
    margin-top: -86px;
    @include breakpoint($bp-charlie) {
        margin-top: -110px;
    }
}

a {
	color: $primary;
	@include hover-fade();
	&:hover {
		color: $secondary;
	}
}
.unstyled-link{
    color: $body !important;
}

.overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #000;
    // don't use rgba so we use animations on it
    opacity: 0.8;
    &.gradient {
        background: linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -moz-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        opacity: 0.4 !important;

    }
    .no-js & {
        opacity: 0.4;
    }
}

.pagination {
    list-style: none;
    margin: 0 auto;
    text-align: center;
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
        margin: 0!important;
    }
    li {
        margin: 0px !important;
        display: inline-block;
        float: none;
        text-align: center;
        font-family: $headline-font;
        font-size: 17px;
        font-weight: 600;
        color: #999999;
        position: relative;
        padding: 0;
        @include breakpoint($bp-bravo) {
            padding:0 12px 0 10px;
        }
        &:last-of-type {
            &:after {
                display: none!important;
            }
        }
    }
    a, span {
        line-height: 1.6em;
        margin: 0px 10px;
        padding: 5px 2px;
        display: inline-block;
        font-size: 17px;
        text-decoration: none;
        position: relative;
        color: #c1bcb6;
        svg {
            margin-bottom: 3px;
            width: 14px;
            height: 18px;
            fill: #3b3d4d;
            @include transition(all 0.2s ease-in-out);
            &:hover {
                fill: $secondary;
            }
        }
    }
    .prev {
        @include transform(rotate(-180deg));
    }
    span.current {
        color: $mineshaft;
        &:after {
            content: '';
            position: absolute;
            left: 6px;
            transform: translateX(-50%);
            bottom: 0;
            height: 1px;
            width: 12px;
            background: $mineshaft;
        }
    }
    a {
        font-family: $headline-font;
        font-weight: 600;
        text-transform: uppercase;
        &:hover {
            color: $mineshaft;
        }
    }
    &__left{
        padding: 45px 15px;
        @include breakpoint($bp-charlie) {
            padding: 20px 0 70px;
        }
    }
    &__right{
        padding: 45px 15px;
        @include breakpoint($bp-charlie) {
            padding: 20px 0 70px;
        }
        a{
            font-size: 13px;
            letter-spacing: 0.1em;
        }
    }
}


svg title {
  pointer-events: none;
}
