.container {
    margin: 0 auto;
    padding: 0 30px;
    max-width: 100%;
    @include breakpoint($bp-charlie) {
        width: 100%;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

main {
    float: left;
    width: 100%;
}

hr {
    width: 140px;
    height: 1px;
    background: $hrColor;
    border-radius: 0px;
    margin: 25px auto 25px auto;
    @include breakpoint($bp-charlie) {
        margin: 30px auto 30px auto;
    }
    @include breakpoint($bp-bobby) {
        margin: 40px auto 40px auto;
    }
    &.divider {
         margin: 35px auto 0px auto;
        @include breakpoint($bp-charlie) {
            margin: 55px auto 0px auto;
        }
        @include breakpoint($bp-bobby) {
            margin: 60px auto 0px auto;
        }
    }
    &.long {
        width: 100%;
        height: 2px;
        max-width: 100%;
        margin: 50px auto 0 auto;
        background: #eaeaea!important;
        @include breakpoint($bp-charlie) {
            margin: 0;
        }
    }
}

.issues-page {
    hr {
        &.divider {
            margin: 40px auto 0px auto;
            @include breakpoint($bp-charlie) {
                margin: 55px auto 0px auto;
            }
            @include breakpoint($bp-bobby) {
                margin: 75px auto 0px auto;
            } 
        }
    }
}

.content {
    float: left;
    width: 100%;
    .col-md-8 {
        margin: 0 auto;
        display: table;
        float: none;
    }
    .bottom-share {
        padding: 25px 0;
        width: 100%;
        float: left;
        text-align: center;
        display: block;
        @include breakpoint($bp-bobby) {
            display: none;
        }
        .social {
            display: table;
            float: none;
            margin: 0 auto;
            span {
                display: none;
            }
            ul {
                li {
                    a {
                        color: #666666;
                        &:hover {
                            color: $secondary;
                        }
                        i {
                            font-size: 23px;
                            @include breakpoint($bp-charlie) {
                                font-size: 29px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.upnext {
    margin-top: 0!important;
    margin-bottom: 0;
    height: auto;
    position: relative;
    padding: 85px 0 85px;
    @include hover-fade();
    margin: 0 auto;
    width: 100%;
    display: table;
    z-index: 25;
    max-width: 1170px;
    background:$primary;
    border-top: 3px solid #527846;
    border-radius: 0px;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.15);
    &:hover {
        background-color: $newmexico;
        border-top: 3px solid darken($newmexico, 10%);
    }
    h2 {
        text-align: center;
        color: #fff;
        margin: 0 auto;
        max-width: 650px;
    }
    h6 {
        text-align: center;
        color: #fff;
        margin-bottom: 10px;
        @include breakpoint($bp-bobby) {
            font-size :20px;
        }
    }
    @include breakpoint($bp-bravo) { 
        padding: 85px 32px 85px;
    }
    @include breakpoint($bp-charlie) { 
        padding: 50px 0;
        height: 350px;
    }
    @include breakpoint($bp-bobby) { 
        padding: 150px 92px;
        height: 350px;
    }
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .container {
        height: 100%;
        max-width: 100%;
        padding: 0px 40px;
        @include breakpoint($bp-charlie) { 
            max-width: 90%;
            padding: 0px 60px;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        @include breakpoint($bp-echo) { 
            max-width: 1190px;
        }
    }
    .arrow {
        width: 20px;
        position: absolute;
        top: 50%;
        margin: 0 auto;
        right: -16px;
        bottom: 0px;
        @include transform(translateY(-50%) rotate(0));
        svg {
            fill: #fff;
        }
        @include breakpoint($bp-adams) {
            right: -8px;
        }
        @include breakpoint($bp-bravo) {
            right: -16px;
        }
        @include breakpoint($bp-charlie) {
            width: 45px;
            margin: 0;
            left: auto;
            right: -32px;
            bottom: auto;
        }
        @include breakpoint($bp-bobby) {
            right: 0;
        }
    }
}

.vertical-centered {
    float: left;
    width: 100%;
    text-align: center;
    @include breakpoint($bp-charlie) {
        @include vertical-align();
    }
}

.video-cover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: cover !important;
    z-index: 2;
    cursor: pointer;
}
.videoWrapper {
	&:not(.featured-video) {
		position: relative;
		padding-bottom: 35.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

    .play-button {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        cursor: pointer;
        .play-bg {
            background: #fff;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin: -14px 0 0 -14px;
            position: absolute;
            @include hover-fade();
            @include breakpoint($bp-charlie) {
                width: 44px;
                height: 44px;
                margin: -22px 0 0 -22px;
            }
        }
        svg {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: .3s;
            @include breakpoint($bp-charlie) {
                width: 90px;
                height: 90px;
            }
        }
        &:hover {
            svg {
                @include transform(translateY(-50%) scale(1.12));
            }
        }
    }
}
