.nopositionsticky {
  .main-header {
    //background: $body;
  }

  //apply rules when header is sticky
  .is-sticky.top {
    top: 0px;
    position: fixed;
    width:100%;
  }

}