
// max breakpoint is breakpoint - 1px so that min/max can match up
@mixin breakpoint($breakpoint, $width: min) {
  @if $width == "max" {
    @media (#{$width}-width: $breakpoint - 1px) {
      @content;
    }
  } @else {
    @media (#{$width}-width: $breakpoint) {
      @content;
    }
  }
}

@mixin breakpoint-minmax($breakpointmin, $breakpointmax) {
  @media (min-width: $breakpointmin) and (max-width: $breakpointmax - 1px) {
    @content;
  }
}

@mixin breakpoint-height($breakpoint, $height: min) {
  @if $height == "max" {
    @media (#{$height}-height: $breakpoint - 1px) {
      @content;
    }
  } @else {
    @media (#{$height}-height: $breakpoint) {
      @content;
    }
  }
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin hover-fade {
	-o-transition: 0.35s;
	-ms-transition: 0.35s;
	-moz-transition: 0.35s;
	-webkit-transition: 0.35s;
	transition: 0.35s;
}


//most of these aren't necessary because of autoprefixer
@mixin transform($transforms) {
     -moz-transform: $transforms;
       -o-transform: $transforms;
      -ms-transform: $transforms;
  -webkit-transform: $transforms;
          transform: $transforms;
}

@mixin transition( $val : ease 0.5s ) {
  -webkit-transition: $val;
  -moz-transition:$val;
  -o-transition:$val;
  -ms-transition:$val;
  transition:$val;
}

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin animate($name, $duration, $iteration, $direction) {
  -webkit-animation-duration: $duration;
     -moz-animation-duration: $duration;
       -o-animation-duration: $duration;
          animation-duration: $duration;
  -webkit-animation-iteration-count: $iteration;
     -moz-animation-iteration-count: $iteration;
       -o-animation-iteration-count: $iteration;
          animation-iteration-count: $iteration;
  -webkit-animation-name: $name;
     -moz-animation-name: $name;
       -o-animation-name: $name;
          animation-name: $name;
  -webkit-animation-direction: $direction;
     -moz-animation-direction: $direction;
       -o-animation-direction: $direction;
          animation-direction: $direction;
}

@mixin animation-name($name...) {
  -o-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name;
}

@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin box-shadow($top, $left, $blur, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $color #{$inset};
  -moz-box-shadow:$top $left $blur $color #{$inset};
  box-shadow:$top $left $blur $color #{$inset};
}

@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

@mixin transform-origin ($origin) {
     -moz-transform-origin: $origin;
         -o-transform-origin: $origin;
        -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
          transform-origin: $origin;
}

@mixin transform-style($val) {
  -webkit-transform-style: $val;
  -moz-transform-style: $val;
  -ms-transform-style: $val;
  transform-style: $val;
}

@mixin perspective($val) {
  -webkit-perspective: $val;
  -moz-perspective: $val;
  -ms-perspective: $val;
  perspective: $val;
}