.alert-bar {
	width: 100%;
	background: $newmexico;
	position: relative;
	z-index: 9;
	@include hover-fade();

	&.has-link {
		&:hover {
			background: $secondary;
		}

		.close-icon .fa:hover {
			color: $body;
		}
	}

	&.-alt {
		background-color: $bg-light;
	}

	//hidden by default even if turned on, visible if the cookie script says it can be
	display: none;
	.ok-to-alert &{
		display: block;
		@include breakpoint($bp-charlie) {
			position: fixed;
			bottom: 0;
			left: 0;
		}
	}
}

.alert-bar__centered-col {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.alert-bar__close {
	cursor: pointer;
	position: absolute;
	top: 50%;
	height: 100%;
	@include transform(translateY(-50%));
	right: 15px;
	width: 21px;
	height: 21px;
	min-height: 0;
	border-radius: 100%;
	background: rgba(255,255,255,0.25);
	@include hover-fade();

	.alert-bar.-alt & {
		background-color: rgba(34, 34, 34, 0.2);
	}

	&.-top-aligned {
		top: 20px;
		@include transform(translateY(0));
	}

	svg {
		position: absolute;
		top: 5px;
		left: 5px;
		width: 11px;
		height: 11px;
		fill: #fff;
	}

	&:hover {
		background: rgba(#fff,1);

		svg {
			fill: $secondary;
		}

		.alert-bar.-alt & {
			background-color: $primary;

			svg {
				fill: #fff;
			}
		}
	}
}

.alert-bar__simple {
	padding: 9px 25px;
	@include breakpoint($bp-charlie) {
		padding: 14px 25px;
	}
	p {
		padding: 0px 25px;
		margin: 0px;
		text-align: center;
		color: #fff;
		font-family: $primary-font;
		font-weight: 400;
		line-height: 116%;
		font-size: 15px;

		@include breakpoint($bp-charlie) {
			padding: 0 15px;
			line-height: 125%;
			font-size: 20px;
		}

		span {
			text-transform: uppercase;
			margin-right: 10px;
			font-size: 15px;
		}
	}
}

.alert-bar__simple__link {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.alert-bar__flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	
	@include breakpoint($bp-bobby) {
		flex-direction: row;
		padding-top: 54px;
		padding-bottom: 54px;
	}
}

.alert-bar__description {
	@include breakpoint($bp-bobby) {
		max-width: 542px;
	}
}

.alert-bar__headline {
	.alert-bar:not(.-alt) & {
		color: #fff;
	}
}

.alert-bar__text {
	.alert-bar:not(.-alt) & {
		color: #fff;
	}

	@include breakpoint($bp-bobby) {
		font-size: 18px;
	}
}

.alert-bar__wrapper {
	@include breakpoint($bp-bobby) {
		padding-left: 45px;
	}

	&.-fill {
		flex: 1;
	}
}

.alert-bar__button {
	background-color: #fff;
	color: $primary;
}

.alert-bar__video {
	text-align: center;
	font-family: $headline-font;
	font-size: 16px;
	color: #fff;
	display: block;

	&:after {
		content: 'watch video';
	}

	svg {
		fill: #fff;
		background-color: #000;
		width: 124px;
		height: 124px;
		display: block;
		margin: 0 auto 20px auto;
		border-radius: 999px;
		@include hover-fade();
	}

	&:hover {
		color: #fff;

		svg {
			fill: $secondary;
			background-color: #fff;
		}
	}
}

.alert-bar__form {
	.form-field {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		&.first-name,
		&.email {
			margin-bottom: 4px;
		}

		&.zip {
			margin-bottom: 13px;
		}

		input {
			border-color: transparent;
			border-radius: 0;
			box-shadow: 12px 20px 40px rgba(0, 0, 0, 0.1);
		}
	}
}

.alert-bar__donation-wrapper {
	@include breakpoint($bp-bobby) {
		max-width: 400px;
	}
}

.alert-bar__donation-button {
	font-size: 16px !important;
	padding-top: 3px !important;
	padding-bottom: 3px !important;

	&.-alt {
		font-size: 11px !important;
		height: auto !important;
		color: $secondary !important;
		border-color: $secondary !important;
		box-shadow: 0 0 0 transparent !important;

		&:hover {
			color: #fff !important;
			border-color: darken($secondary, 10%) !important;
		}
	}
}

.alert-bar__donation-caption {
	clear: both;
	margin-top: 10px;

	p {
		text-align: center;
		font-family: $headline-font;
		font-weight: 400;
		font-style: normal;
		line-height: 1.2;
		color: $body;
	}
}
