$buttonBorderRadius: 5em;//not sure why button is 1px less than modules but it is atm
.secondary a,
.btn {
	background: $primary;
	border-radius: $buttonBorderRadius;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	color: #fff;
	cursor: pointer;
	display: block;//should be inline-block, for the base case...
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	font-weight: 600;
	font-family: $headline-font;
	line-height: 120%;
	@include hover-fade();
	@include breakpoint($bp-alpha) {
		min-width: 255px;
	}
	max-width: 350px;
	padding: 17px 15px;
	text-align: center;
	width: auto;
	@include breakpoint($bp-alpha, max) {
		width: 100%;
	}
	&--ghost{
		background: $mallard;
	}
	&:hover {
		border-color: $mineshaft;
		background: $mineshaft;
		color: #fff;
	}
	&.alt {
		background: $secondary;
		&:hover {
			background: $primary;
		}
	}
}