.social {
	&.links {
		float: left;
		font-size: 20px;
		margin-top: 8px;
		span {
			float: left;
			display: block;
			text-align: center;
			font-size: 12px;
			text-transform: uppercase;
			margin: 13px 15px 0 15px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			float: right;
			li {
				list-style-type: none;
				float: left;
				a {
                    padding: 8px 15px 6px;
					float: left;
					color: $body;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
}

.social-nav {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    opacity: 1;
    @include transform(translateY(-50%) translateX(0px));
    @include hover-fade();
    display: none;
    &.animate {
        opacity: 0;
        @include transform(translateY(-50%) translateX(50px));
    }
    &.behind {
        z-index: -99;
    }
    @include breakpoint($bp-bobby) {
        display: block;
        width: 56px;
        position: fixed;
        right: 0!important;
        top: 50%!important;
        left: auto!important;
        bottom: auto;
        @include transform(translateY(-50%));
    }
    .social-side {
        display: table;
        width: 56px;
        display: none;
        @include breakpoint($bp-charlie) {
            display: block;
        }
        &:hover {
            .box {
                border: 1px solid $colorado;
                background: $colorado;
                i {
                    color: #fff;
                }
            }
            .share-slide {
                left: -160px;
                opacity: 1;
            }
            p {
                color: $colorado;
            }
        }
        p {
            font-family: $headline-font;
            font-size: 11px;
            letter-spacing: 0.04em;
            color: #c1bcb6;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 5px;
            width: 100%;
        }
        .share-slide {
            position: absolute;
            top: 0px;
            width: 160px;
            background: #faf8f5;
            left: 0;
            opacity: 0;
            height: 60px;
            padding-left: 9px;
            border: 1px solid $mineshaft;
            border-right: none;
            @include hover-fade();
            z-index: 5;
            .social.links {
                margin-top: 8px;
                ul {
                    li {
                        a {
                            color: $mineshaft;
                            &:hover {
                                color: $colorado;
                            }
                        }
                    }
                }
            }
        }
        .box {
            background: #faf8f5;
            border: 1px solid #c1bcb6;
            border-right: none;
            text-align: center;
            padding: 18px 0 13px;
            cursor: pointer;
            color: #c1bcb6;
            margin-bottom: 4px;
            z-index: 6;
            position: relative;
            @include hover-fade();
            i {
                font-size: 22px;
                font-weight: 400;
                color: $mineshaft;
                @include hover-fade();
            }
            &:hover {
                border: 1px solid $colorado;
                background: $colorado;
                i {
                    color: #fff;
                }
            }
        }
    }
}
