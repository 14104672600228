.articles {
	float: left;
	width: 100%;
}

.single-post {
	.featured-image, .featured-video {
		max-width: 100%;
		margin: 50px auto 0;
		margin-bottom: 0;
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
		.caption {
			margin-top: 15px;
			p {
				text-align: center;
				font-size: 13px;
			}
		}
		iframe {
			width: 100%;
			height: 280px;
			@include breakpoint($bp-charlie) {
				height: 100%;
				min-height: 600px;
			}
			@include breakpoint($bp-bobby) {
				min-height: 700px;
			}
		}
	}
}

.post-feed {
	.content {
		background: $springwood;
		padding: 30px 20px;
		@include breakpoint($bp-charlie) {
			padding: 50px 40px;
		}
		.container {
			padding: 0;
			@include breakpoint($bp-charlie) {
				width: 1115px;
			}
		}
	}
	.featured-content {
		position: relative;
		a.full-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.featured-image {
			width: 100%;
			background-size: cover!important;
			background-position: 50% 50%;
			height: 240px;
			@include breakpoint($bp-charlie) {
				height: 380px;
			}
			@include breakpoint($bp-bobby) {
				height: 520px;
			}
			@include breakpoint($bp-echo) {
				height: 555px;
			}
		}
		.featured-video {
			margin-bottom: 0;
			iframe {
				width: 100%;
				height: 240px;
				@include breakpoint($bp-charlie) {
					height: 380px;
				}
				@include breakpoint($bp-bobby) {
					height: 520px;
				}
				@include breakpoint($bp-echo) {
					height: 555px;
				}
			}
		}
	}
}

article:not(.bills__single) {
	float: left;
	width: 100%;
	position: relative;
	background: #fff;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	margin-bottom: 25px;
	@include breakpoint($bp-charlie) {
		margin-bottom: 45px;
		&:hover {
			.overlay-share {
				opacity: 1;
			}
			.main-content {
				h3 {
					a {
						color: $primary;
					}
				}
				.read-more {
					a {
						color: $secondary;
					}
				}
			}
		}
	}
	.overlay-share {
		position: absolute;
		right: 0;
		bottom: 0;
		@include hover-fade();
		opacity: 0;
		z-index: 100;
		.social {
			margin: 0;
			float: right;
			display: none;
			height: 77px;
			background: transparent;
			padding: 25px;
			@include breakpoint($bp-bobby) {
				display: block;
			}
			span {
				margin: 4px 10px 0;
				font-family: $headline-font;
				color: #666666;
				text-transform: none;
				font-weight: 600;
				font-size: 14px;
				display: none;
			}
			ul {
				li {
					a {
						font-size: 18px;
						padding: 0px 8px;
						color: #666666;
						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}
	}
	&:last-child {
		margin: 0px;
		border-bottom: 0px;
	}
	img {
		max-width: 100%;
		height: auto;
		margin: 0;
	}
	.main-content {
		width: 100%;
		float: left;
		padding:  25px 20px;
		position: relative;
		@include breakpoint($bp-charlie) {
			padding:  70px 40px;
		}
		.full-link {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 99;
		}
	}
	h3, .excerpt {
		display: table;
		width: 100%;
		margin: 0 auto 20px;
		max-width: 785px;
		text-align: center;
	}
	.excerpt p:last-of-type {
		margin-bottom: 0;
	}
	h3 {
		a {
			color: $body;
		}
	}
	.read-more {
		display: table;
		width: 100%;
		margin: 0 auto 10px;
		max-width: 785px;
		text-align: center;
		a {
			color: $primary;
			font-size: 16px;
			font-family: $headline-font;
			font-weight: 600;
			position: relative;
			padding-right: 15px;
			&:after {
				right: 0;
				content: "\f105";
				position: absolute;
				font-size: 18px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				bottom: -4px;
			}
		}
	}
	.meta {
		display: table;
		width: 100%;
		margin: 0 auto 10px;
		max-width: 785px;
		text-align: center;
		span {
			margin: 0 20px;
			font-size: 15px;
			font-family: $headline-font;
			font-weight: 600;
			color: #999999;
			@include breakpoint($bp-charlie) {
				font-size: 17px;
			}
			a {
				color: #999999;
				&:hover {
					color: $secondary
				}
			}
		}
	}
	.btn {
		@include breakpoint($bp-charlie) {
			float: left;
		}
	}
}

.learn-more {
	position: relative;
	float: left;
	width: 100%;
	.go-back {
		margin: 20px auto 0 auto;
		max-width: 750px;
		padding: 0 20px;
		text-align: center;
		a {
			position: relative;
			font-family: $headline-font;
			font-size: 16px;
			font-weight: 600;
			color: #666666;
			padding-left: 15px;
			&:after {
				left: 0;
				content: "\f104";
				position: absolute;
				font-size: 19px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				bottom: -4px;
			}
			&:hover {
				color: $secondary
			}
		}
	}
	.inner-nav {
		float: left;
		width: 100%;
		position: relative;
		margin-top: 35px;
		.next-post {
			float: left;
			width: 100%;
			background: $body;
			height: auto;
			position: relative;
			@include hover-fade();
			@include breakpoint($bp-charlie) {
				width: 50%;
				height: 500px;
			}
			&:hover {
				background: $secondary!important;
			}
			a.full-link {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 99;
			}
			.wrapper {
				max-width: 550px;
				padding: 55px 30px;
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: 98;
				@include hover-fade();
				@include breakpoint($bp-charlie) {
					@include vertical-align();
					padding: 25px;
				}
				h3, p {
					color: #fff;
					text-align: center;
				}
				h3 {
					margin: 20px 0px;
				}
				p.tiny {
					font-family: $headline-font;
					font-size: 16px;
					font-weight: 600;
					margin: 0;
					position: relative;
					span {
						position: relative;
						padding-left: 15px;
						&:after {
							left: 0;
							content: "\f104";
							position: absolute;
							font-size: 18px;
							font-family: FontAwesome;
							font-style: normal;
						    font-weight: normal;
						    text-decoration: inherit;
						}
					}
				}
				p.date {
					font-size: 18px;
					margin: 0;
				}
			}
			&.float-right {
				background: $primary;
				.wrapper {
					.tiny {
						span {
							padding-left: 0;
							padding-right: 15px;
							&:after {
								left: auto;
								right: 0;
								content: "\f105";
							}
						}
					}
				}
			}
		}
		.only-post {
			width: 100% !important;
		}
	}
}
