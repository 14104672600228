.single-state {
	.topper {
		.icons-wrapper {
			a {
				color: #fff;
				font-size: 20px;
				padding: 0 5px;
				&:hover{
					color: $secondary;
				}
			}
		}
	}
	.grid-padding {
		vertical-align: top;
		display: inline-block;
		padding: 0 20px;
		margin-bottom: 40px;
	}
	.state-blocks {
		text-align: center;
		.row{
			//margin-bottom: 100px;
		}
	}
	.state-block {
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		display: block;
		background-color: $secondary;
		width: 100%;
		box-sizing: border-box;
		text-align: center;
		padding: 40px 40px 90px;
		position: relative;
		.block-title {
			margin: 0;
			font-size: 24px;
		}
		hr {
			margin: 25px auto 30px;
			background: $primary;
		}
		p {
			font-size: 16px;
			line-height: 135%;
			color: $primary;
			strong {
				color: $primary;
			}
		}
		.more{
			margin-top: 20px;
			display: inline-block;
			&:hover,
			&:focus{
				color: $tertiary;
			}
		}
		.btn {
			position: absolute;
			width: calc(100% - 110px);
			margin: 0;
			bottom: 40px;
			left: 50%;
			transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
			min-width: 200px;
		}
	}
	.module.last-module {
		margin-bottom: 40px;
	}
	.back-to-container {
		.row {
			margin-top: 60px;
			margin-bottom: 200px;
			text-align: center;
		}
	}
	@include breakpoint($bp-charlie){
	    .row-centered{
	        text-align: center;
	        .col-centered {
	            display:inline-block;
	            float:none;
	            /* reset the text-align */
	            text-align:left;
	            /* inline-block space fix */
	            margin-right:-4px;
	        }
	    }
	}
}

.page-template-template-states {
	.anchor-pagination {
		display: block;
		box-sizing: border-box;
		width: 100%;
		float: none;
		clear: both;
		padding: 0;
		margin: 10px 0 70px;
		text-align: center;
		.alpha-nav {
			margin: 0;
			padding: 0;
			a {
				display: inline-block;
				color: $primary;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: .3em;
				text-transform: uppercase;
				cursor: pointer;
				&:hover {
					color: $primary;
				}
				&:active {
					color: $primary;
				}
				&:after {
					content: '/';
					display: inline-block;
					margin: 0 8px;
					vertical-align: middle;
					color: $primary;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
			}
		}
	}
	.all-states {
		margin-top: 0;
		.row {
			text-align: center;
		}
		.grid-padding {
			padding: 0 27px;
			margin-bottom: 40px;
			@include breakpoint($bp-bobby) {
				padding-top: 40px;
				margin-top: -40px;
				&:nth-of-type(4n + 1) {
					clear: left;
				}
			}
		}
		.state-item {
			text-align: center;
			padding-bottom: 35px;
			position: relative;
			hr {
				position: absolute;
				left: 0;
				width: 100%;
				bottom: 0;
				height: 2px;
				background-color: $bg-light;
			}
			.state-title {
				font-size: 24px;
				margin-bottom: 10px;
				a {
					color: $primary;
					&:hover {
						color: $primary;
					}
				}
			}
			.excerpt {
				font-size: 16px;
				margin-bottom: 0;
				display: block;
				text-align: center;
				p {
					font-size: 16px;
					margin-bottom: 15px;
				}
			}
			.icons-wrapper {
				margin-bottom: 20px;
				a {
					color: $secondary;
					font-size: 20px;
					display: inline-block;
					padding: 0 5px;
					&:hover {
						color: $tertiary;
					}
				}
			}
		}
	}
}
