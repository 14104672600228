.list-page {
	.list-page__intro {
		margin-bottom: 40px;
	}
	.top-introduction {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.list-grid {
		&.search {
			padding: 65px 0 15px 0;
			@include breakpoint($bp-charlie) {
				padding: 85px 0 50px 0;
			}
			.search-highlight {
				background-color: rgba(yellow, 0.5);
			}
		}
		.col-md-9 {
			float: none;
			display: table;
			margin: 0 auto;
		}
		.main-headline {
			margin-top: 40px;
			margin-bottom: 30px;
			@include breakpoint($bp-charlie) {
				margin-bottom: 45px;
				margin-top: 50px;
			}
			h3 {
				text-align: center;
				margin: 0;
			}
		}
		.single-row {
			margin-bottom: 35px;
			padding-bottom: 35px;
			border-bottom: 1px solid #cccccc;
			@include breakpoint($bp-charlie) {
				margin-bottom: 35px;
				padding-bottom: 35px;
			}
			.description {
				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.links {
				margin-top: 15px;
				overflow: hidden;
				@include breakpoint($bp-charlie) {
					margin-top: 25px;
				}
				a {
					display: inline-block;
					font-family: $headline-font;
					font-weight: 600;
					font-size: 16px;
					margin-right: 35px;
					position: relative;
					padding-right: 15px;
					&.learn {
						&:after {
							right: 0;
							content: "\f105";
							position: absolute;
							font-size: 18px;
							font-family: FontAwesome;
							font-style: normal;
							font-weight: normal;
							text-decoration: inherit;
							transform: translate(0, 50%);
					    top: -50%;
					    margin-top: 1px;
						}
					}
					&.download {
						padding-right: 20px;
						span {
							right: 0;
							top: -1px;
							position: absolute;
							svg {
								width: 11px;
								height: auto;
								.st0{
									@include hover-fade();
									fill:none;stroke:$primary;stroke-linecap:round;stroke-miterlimit:10;
								}
							}
						}
						&:hover {
							span {
								svg {
									.st0 {
										stroke: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
