@import 'header/alert-bar.scss';

// special rules for browsers that DO NOT support position: sticky
@import 'header/nopositionsticky.scss';


// Header
.top {
	position: relative;//in case sticky is disabled or unavailable
	position: sticky;
  top: 0;
	z-index: 9998;
	opacity: 1;
	width:100%;
	//@include transition(all 0.3s); //no unecessary transation
}

.admin-bar {
	// .top {
	// 	top: 46px;
	// 	@include breakpoint($bp-charlie) {
	// 		top: 32px;
	// 	}
	// }
	// &.sticky {
	// 	.top {
	// 		@include breakpoint($bp-charlie) {
	// 			top: 32px;
	// 		}
	// 	}
	// }
}


//any special rules for the nav when the sticky scrollpoint is hit
//if the body needs rules, then javascript would need to be altered to apply a class to the body
.is-sticky{
	.main-header{
		background: $bg-light;
		padding: 0px;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		@include breakpoint($bp-charlie) {
			padding: 10px 0;
		}
		@include breakpoint($bp-bobby) {
			padding: 10px;
		}
		.control {
			margin-top: 0.3rem;
			@include breakpoint($bp-charlie) {
				margin-top: .55rem;
			}
			@include breakpoint($bp-bobby) {
				margin-top: .3rem;
			}
		}
	}
	.main-logo{
		.fill--text{
			fill: $bg-dark!important;
		}
	}
	.main-header .logo img, .main-header .logo svg{
		width: 85px;
		height: 50px;
	}
	.main-header nav.primary ul li {
    padding: 16px 7px 10px;
	}
	.main-header .search-icon {
    margin-top: 2px;
	}
	.main-header .search-icon i,
	.main-header nav.primary a {
    color: $mallard;
	}
	.hamburger{
		margin: 0 0 12px 0;
		@include breakpoint($bp-charlie) {
			margin: 0 0 15px 0;
		}
	}
	.hamburger-inner {
		&, &:before, &:after {
			background-color: $mallard;
		}
	}
}


.pusher {
	height: 0px;
	@include transition(all 0.3s);
}

body.search-open {
    .pusher {
        margin-top: 0px;
    }
    .top {
        top: 61px;
    }
    .top-search {
        top: 0;
        opacity: 1;
    }
}

.top-search {
	height: 61px;
	padding: 0px 0;
	position: fixed;
	top: 0;
	width: 100%;
	top: -61px;
	@include transition(all 0.3s);
	z-index: 9999;
	opacity: 0;
	.container {
		padding: 0;
		max-width: 100%;
		width: 100%;
	}
	form {
		position: relative;
		height: 61px;
		display: block;
		float: left;
		width: 100%;
	}
	.arrow {
		position: absolute;
		right: 23px;
		top: 50%;
		@include transform(translateY(-50%));
		svg {
			width: 22px;
			height: 22px;
			line-height: 1;
			color: $body;
			@include hover-fade();
			cursor: pointer;
			&:hover {
				color: $secondary;
			}
		}
	}
	input[type="text"] {
		width: 100%;
		height: 61px;
		color: $body;
		font-family: $headline-font;
		font-weight: 700;
		font-size: 29px;
		line-height: 61px;
		letter-spacing: 0;
		opacity: 1;
		padding: 0px 60px 00px 10px;
		border: none!important;
		@include transition(all 0.3s);
		box-shadow: none!important;
		position: relative;
		top: 47%;
		@include transform(translateY(-50%));
		&::-webkit-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&::-moz-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-ms-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-moz-placeholder { 
			top: 0%;
			position: relative;
		  	color: $body;
		}
	}
	input[type="submit"] {
		display: none;
	}
}

.main-header {
	width: 100%;
	padding: 4px 0;
	//height: $header-mobile-height;
	//background: rgba(255, 0, 150, 0.2);
	//background: $navBackground;
	//@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	@include transition(all 0.3s ease-in-out);
	//position: absolute;
	@include breakpoint($bp-charlie) {
		//height: $header-height;
		padding: 30px 0 10px;
	}
	.container-navigation {
		padding: 4px 10px;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		@include breakpoint($bp-charlie) {
			padding: 0 20px 0 20px;
		}
	}
	.search-icon {
		float: right;
		padding: 9px 10px;
		margin-left: 12px;
		margin-right: 50px;
		cursor: pointer;
		@include hover-fade();
		@include breakpoint($bp-bobby) {
			margin-left: 12px;
			margin-right: 0;
		}
		&:hover {
			i {
				color: $secondary;
			}
		}
		i {
			@include hover-fade();
			color: #c1bcb6;
			font-size: 18px;
			@include breakpoint($bp-charlie) {
				font-size: 18px;
			}
		}
	}
	.main-logo{
		display: inline-block;
		.fill--text{
			fill: $bg-light;
		}
		.fill--fig{
			fill: $newmexico;
		}
	}
	.logo {
		img, svg {
			display: inline-block;
			width: 112px;
			height: 67px;
			margin: 3px 0 0 0;
			@include hover-fade();

		}
	}
	.control {
		float: right;
		margin-top: 0.75rem;
		@include breakpoint($bp-bobby) {
			margin-top: 0.15rem;
		}
	}
	.social {
		&.links {
			display: none;
			@include breakpoint($bp-echo) {
				display: block;
				max-width: 70px;
				height: 30px;
				overflow: hidden;
				float: right;
				margin: 8px 20px 0px 0;
				@include hover-fade();
				color: #fff;
			}
			ul {
				@include breakpoint($bp-echo) {
					float: none;
					min-width: 100px;
				}
			}
			li {
				i {
					font-size: 17px;
				}
				a {
					color: $socialColor;
					padding: 0 11px;
					&:hover {
						color: $socialHover;
					}
				}
			}
		}
	}
	nav {
		float: right;
		&.primary {
			a {
				color: $navColor;
				&:hover {
					color: $navColorHover;
				}
			}
		}
	}
}

.page-no-topper .main-header,
.page-target-topper .main-header,
.page-issue-topper .main-header{
	.main-logo{
		display: inline-block;
		.fill--text{
			fill: $bg-dark;
		}
		.fill--fig{
			fill: $newmexico;
		}
	}
	.search-icon {
		&:hover {
			i {
				color: $secondary;
			}
		}
		i {
			color: $mallard;
		}
	}
	.hamburger-inner {
		background-color: $mallard;
		&:after, &:before {
			background-color: $mallard;
		}
	}
	nav {
		&.primary {
			a {
				color: $mallard;
				&:hover {
					color: $navColorHover;
				}
			}
		}
	}
}

.page-no-topper{
	#container-wrap > main{
		padding-top: 7rem;
	}
}

// Navigation
.main-header nav {
	&.primary {
		display: none;

		@include breakpoint($bp-bobby) {
			display: block;
		}

		@include breakpoint($bp-charlie) {
			float: right;
			margin: -4px 0 0 0;
			@include hover-fade();
			ul {
				margin: 0;
				display: table;
				li {
					margin: 0;
					padding: 13px 8px;
					position: relative;
					font-family: $headline-font;
					font-size: 11px;
					text-transform: uppercase;
					letter-spacing: 0.08em;
					font-weight: 600;
					@include breakpoint($bp-echo) {
						padding: 13px 10px;
					}
					&.home {
						display: none;
					}
					&.menu-item-has-children {
						&:hover {
							.sub-menu {
								display: block;
								visibility: visible;
								margin-top: 0;
								opacity: 1;
								li {
									-webkit-animation: fadeIn 0.5s ease-in-out; 
									-webkit-animation-fill-mode: both;
									animation: fadeIn 0.5s ease-in-out; 
									animation-fill-mode: both;
								}
							}
						}
					}
					&:first-child {
						border-left: 0px;
					}
					&:last-child {
						margin-right: 0px;
					}
					.sub-menu {
						width: 270px;
						position: absolute;
						top: 65px;
						left: 58%;
						-webkit-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
						background: rgba($dropDownBg, 1);
						padding: 25px;
						visibility: hidden;
						opacity: 0;
						margin-top: 20px;
						@include hover-fade();
						@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
						&:before {
							content: '';
							width: 0; 
							height: 0; 
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
							border-bottom: 12px solid rgba($dropDownBg, 1);
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							margin: 0 auto;
						}
						&:after {
							content: '';
							height: 22px;
							width: 100%;
							position: absolute;
							top: -22px;
							left: 0;
							background:transparent;
						}
						li {
							width: 100%;
							margin: 0 0 10px 0;
							padding: 0;
							border: 0!important;
							line-height: 100%;
							font-family: $primary-font;
							text-transform: none;
							font-size: 19px;
							letter-spacing: 0;
							&:last-child {
								margin-bottom: 0!important;
							}
							a {
								color: $dropDownColor;
								margin: 0;
								&:hover {
									color: $dropDownHover;
									opacity: 1!important;
								}
							}
						}
					}
				}
			}
		}
		/* classes that can be used to style nav when # of items gets really long*/
		&.primary.over-five-items{

		}
		&.primary.over-six-items{

		}
		&.primary.over-fourty-characters{
			// & > ul > li{
			// 	padding: 11px 10px;
			// 	font-size: 14px;
			// }
		}
		&.primary.over-fifty-characters{
			
		}
	}
	&.secondary {
		display: none;
		ul {
			li {
				a {
					display: block;
					float: left;
					text-align: center;
					background: $primary;
					color: #fff;
					height: 43px;
					border-radius: 3px;
					line-height: 41px;
					width: 155px;
					font-size: 16px;
					font-weight: 600;
					font-family: $headline-font;
					@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
					&:hover {
						background: #fff;
						color: $secondary;
					}
					@include breakpoint($bp-echo) {
						width: 175px;
					}
				}
				&:last-child {
					a {
						background: $secondary;
						&:hover {
							background: #fff;
							color: $secondary;
						}
					}
				}
			}
		}
		@include breakpoint($bp-bobby) {
			display: block;
			float: right;
			@include hover-fade();
			padding-top: 2px;
			ul {
				li {
					&:last-child {
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}
	@include breakpoint($bp-charlie) {
		ul {
			li {
				float: left;
				list-style-type: none;
			}
		}
	}
}

// Mobile Nav Icon
.hamburger {
	float: right;
	margin:  0 0 30px 0;
	z-index: 11;
	position: absolute;
	bottom: 0;
	right: 20px;
	z-index: 9998;
	@include hover-fade();
	@include breakpoint($bp-charlie) {
		//margin: 52px 0 0 0;
	}
	@include breakpoint($bp-bobby) {
		display: none;
	}
	.hamburger-inner {
		&, &:before, &:after {
			@include hover-fade();
		}
	}
	&:hover {
		.hamburger-inner {
			background-color: $secondary;
			width: 33px;
			&:after, &:before {
				background-color: $secondary;
				width: 33px;
			}
		}
	}
	&.is-active {
		.hamburger-inner {
			&, &:after {
				background-color: $springwood;
				width: 33px;
			}
		}
	}
}

// Mobile Nav
.mobile-nav {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba($body,1);
	z-index: 10;
	height: 100%;
	display: none;
	nav {
		float: left;
		width: 100%;
		&.mobile {
			@include vertical-align();
			ul {
				margin: 0px;
				padding: 0px;
				float: left;
				width: 100%;
				li {
					float: left;
					width: 100%;
					text-transform: uppercase;
					text-align: center;
					font-size: 30px;
					margin: 0 0 10px;
				}
			}
		}
	}
}


/* Menu */
.menu-wrap {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	right: 0;
	background: transparent;
	font-weight: 600;
	opacity: 0;
	@include hover-fade();
	-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	left: 100%;
	.align-middle {
		padding: 0 30px;
		width: 100%;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.menu {
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		li {
			width: 100%;
			float: left;
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			font-family: $headline-font;
			margin-bottom: 0px;
			a {
				color: #fff;
				padding: 5px 0px;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.secondary {
		margin: 20px 0 15px 0;
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		display: block;
		li {
			display: table;
			margin: 0 auto 10px auto!important;
			text-align: center;
			a {
				float: none;
				display: table;
				margin: 0 auto;
				width: 100%;
				height: 52px;
				max-width: 325px;
				font-size: 18px;
			}
		}
	}
	.social-mobile {
		float: left; 
		width: auto;
		width: 100%;
		.social {
			display: table;
			margin: 0 auto;
			float: none;
			width: auto;
			ul {
				li {
					a {
						color: #999999;
						&:hover {
							color: $secondary
						}
						i {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
}

.menu-top {
	line-height: 58px;
}

.menu-top .profile {
	display: inline-block;
	padding: 8px 10px;
	line-height: 42px;
}

.menu-top .profile,
.menu-side {
	width: 300px;
}

.menu-top .profile img {
	float: left;
	margin-right: 1em;
}

.icon-list { 
	display: inline-block;
	font-size: 1.25em;
}

.icon-list a {
	margin: 0 1em 0 0;
	padding: 0;
}

@media screen and (max-width: 32em) {
	.icon-list {
		padding-left: 1em;
	}
}

.menu-side a {
	display: block;
	padding: 1.2em;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.menu-side a:first-child {
	border-top: 1px solid rgba(0,0,0,0.1);
}



#container-wrap {
	float: left;
	width: 100%;
	opacity: 1;
	left: 0;
	@include hover-fade();
}

/* Shown menu */
.show-menu {
	.top {
		opacity: 0;
	}
	#container-wrap {
		left: -100%;
		//opacity: 0;
		-webkit-transform: translate3d(-100%,0,0);
		transform: translate3d(-100%,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
		transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
	}
	.menu-wrap {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		left: 0;
	}
}
